// Globals
import { version } from "~/package.json";

// Types
import type { ContentfulPageComposableOptions } from "~/types/contentful";

export default function useContentfulPage(
  contentfulPage: MaybeRef<ContentfulPageResponse | null>,
  options: Partial<ContentfulPageComposableOptions> = {},
) {
  /**
   * This sets the shared state for the language switch to pick up.
   *
   * @deprecated this approach should be removed in favor of route middleware. This will not remain in this composable.
   */
  const globalPageTypeId = useState("pagetypeId");
  const tracking = useTracking();

  const { setSeoMeta, pageTypeToTrack } = options;
  const _page = unref(contentfulPage);

  if (_page?.contentfulId) {
    globalPageTypeId.value = _page?.contentfulId;
  }

  const heroContent = computed(() => {
    if (!_page?.hero) {
      return null;
    }

    return {
      fields: {
        content: [
          {
            ..._page.hero,
            fields: {
              ..._page.hero.fields,
              titleHeadlineLevel: 1,
            },
          },
        ],
      },
    };
  });

  const meta = computed(() => _page?.meta.fields || null);

  if (setSeoMeta && meta.value) {
    useSeoMeta({
      ogDescription: meta.value.socialDescription || meta.value.seoDescription,
      description: meta.value.seoDescription,
      ogTitle: meta.value.socialTitle || meta.value.seoTitle,
      title: meta.value.seoTitle,
      twitterCard: "summary_large_image",
    });
  }

  if (import.meta.client && pageTypeToTrack) {
    tracking.trackPageInfo({
      pageType: pageTypeToTrack,
      pageTitle: meta.value?.seoTitle,
      version,
    });
  }

  return {
    heroContent,
    meta,
  };
}
